<template>
  <section class="d-flex flex-column flex-fill">
    <hsPageHeader
      :title="$t('sparkmembers.comments.title')"
      :sub-title="$t('sparkmembers.comments.subtitle')"
      :back-text="$t('breadcrumb.ProductDashboard')"
      back-route="ProductDashboard"
    >
    </hsPageHeader>
    <hsLoading v-if="isLoading" class="flex-fill" />
    <section v-else class="py-3 px-4 py-sm-4 px-sm-5 d-flex flex-fill flex-column mt-4 pb-4 overflow-auto">
      <div class="row flex-nowrap flex-fill position-relative">
        <hs-sidebar class="position-relative w-100" :opened="sidebar">
          <template v-slot:content>
            <NoTopicsWarning v-if="noTopics" />
            <div class="flex-1">
              <TopicsTable id="topics-table" :class="sidebar && 'pr-1'" :topics="topics" @selected="onSelected" />
            </div>
            <hs-pagination
              v-if="!noTopics"
              v-model="currentPage"
              :go-page-text="$t('sparkmembers.coupons.views.list.index.pagination.go-to-page')"
              :per-page="10"
              :total-rows="totalNumberOfTopics"
              align="center"
            />
          </template>
          <template v-slot:sidebar>
            <TopicsSidebar
              id="topics-sidebar"
              :topic="topic"
              v-if="sidebar"
              @close="closeSidebar"
              @send="onSendAnswer"
            />
          </template>
        </hs-sidebar>
      </div>
    </section>
  </section>
</template>

<script>
import { forumService } from '@/services';
import { hsLoading, hsSidebar } from '@/components';
import { mapState } from 'vuex';
import dayjs from 'dayjs';
import hsPageHeader from '@/components/_structures/PageHeader';
import NoTopicsWarning from './components/NoTopicsWarning';
import ToastHelper from '@/shared/helpers/toast';
import TopicsSidebar from './components/TopicsSidebar';
import TopicsTable from './components/TopicsTable';

export default {
  components: { hsPageHeader, TopicsTable, hsSidebar, TopicsSidebar, hsLoading, NoTopicsWarning },
  data: () => ({
    topics: [],
    topic: {},
    sidebar: false,
    isLoading: true,
    currentPage: 1,
    noTopics: false,
    totalNumberOfTopics: 0,
  }),
  watch: {
    currentPage(newVal) {
      this.mountList(newVal);
    },
  },
  mounted() {
    this.mountList(1);
  },
  computed: {
    ...mapState({
      product: state => state.product.selectedProduct,
    }),
  },
  methods: {
    async mountList(page) {
      this.isLoading = true;
      this.sidebar = false;
      try {
        const response = await forumService.getAll([{ key: 'school_product_id', value: this.product.id }]);
        if (response.forums.length > 0) {
          this.forum = response.forums[0];
          const data = await forumService.getForumTopics(response.forums[0].id, [
            { key: 'per_page', value: '10' },
            { key: 'direction', value: 'desc' },
            { key: 'page', value: page },
            { key: 'created_at', value: '' },
          ]);
          this.topics = data.topics.map(forum => {
            return {
              ...forum,
              created_at: dayjs(forum.created_at).format('DD/MM/YYYY'),
            };
          });
          if (data.total_count === 0) this.noTopics = true;
          this.totalNumberOfTopics = data.total_count;
        } else {
          this.noTopics = true;
        }
      } catch (error) {
        ToastHelper.dangerMessage(error);
      }
      this.isLoading = false;
    },
    onSelected(item) {
      if (this.topic === item) {
        this.closeSidebar();
        return;
      }
      this.topic = item;
      this.sidebar = true;
      setTimeout(function() {
        document.getElementById('topics-sidebar').scrollIntoView({ behavior: 'smooth' });
      }, 500);
    },
    async onSendAnswer(message) {
      this.isLoading = true;
      try {
        await forumService.answerTopic(this.forum.id, this.topic.forum_section_id, this.topic.id, message);
        await this.mountList(this.currentPage);
        ToastHelper.successMessage(this.$t('sparkmembers.comments.message.success'));
      } catch (error) {
        this.isLoading = false;
        ToastHelper.dangerMessage(error);
      }
    },
    closeSidebar() {
      this.sidebar = false;
      this.topic = null;
    },
  },
};
</script>

<style lang="scss" scoped>
#topics-sidebar {
  min-width: 300px;
}
.page-item.active .page-link {
  z-index: inherit;
}
</style>
