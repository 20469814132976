<template>
  <section id="no-members-warning" class="position-absolute h-100 w-100 d-flex flex-column justify-content-center">
    <b-img-lazy class="no-comments-warning__image" :src="noDataImg"></b-img-lazy>
    <h3 id="title" class="text-cherry text-center my-4 font-weight-bold">
      {{ $t('sparkmembers.comments.no-data.title') }}
    </h3>
    <div>
      <p class="text-center">{{ $t('sparkmembers.comments.no-data.subtitle') }}</p>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      noDataImg: require('@/assets/images/commons/no_data.svg'),
    };
  },
};
</script>

<style lang="scss">
#no-members-warning {
  z-index: 1;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 1));
}

.no-comments-warning__image {
  height: 170px;
}
</style>
