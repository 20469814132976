<template>
  <div>
    <hs-table
      :responsive="true"
      :fields="fields"
      :hover="true"
      :items="topics"
      :clickable="true"
      @clickedItem="openTopics"
    >
      <template slot="description" slot-scope="row">
        {{ truncate(row.item.description) }}
      </template>
      <template slot="class" slot-scope="row">
        <hs-button variant="icon" v-if="row.item.class_url">
          <a class="text-decoration-none text-dark" target="_blank" :href="getLink(row.item.class_url)">
            <hs-icon class="mr-1" variant="light" icon="eye" />
            {{ $t('sparkmembers.contents.views.editor.components.lesson.components.page-header.show-lesson') }}
          </a>
        </hs-button>
        <span v-else> -- </span>
      </template>
      <template slot="answered" slot-scope="row">
        <hs-badge pill :variant="getBadgeVariant(row.item.answered)">
          {{
            row.item.answered
              ? $t('sparkmembers.comments.table.status.true')
              : $t('sparkmembers.comments.table.status.false')
          }}
        </hs-badge>
      </template>
    </hs-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'TopicsTable',
  props: {
    topics: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'user.first_name',
          label: this.$t('sparkmembers.comments.table.fields.name'),
          sortable: true,
        },
        {
          key: 'description',
          label: this.$t('sparkmembers.comments.table.fields.title'),
          sortable: true,
        },
        {
          key: 'class',
          label: this.$t('sparkmembers.comments.table.fields.class'),
          sortable: true,
        },
        {
          key: 'created_at',
          label: this.$t('sparkmembers.comments.table.fields.date'),
          sortable: true,
        },
        {
          key: 'answered',
          label: this.$t('sparkmembers.comments.table.fields.status'),
          sortable: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('school', ['getDomain']),
  },
  methods: {
    openTopics(item) {
      this.$emit('selected', item);
    },
    getBadgeVariant(status) {
      return status ? 'success' : 'cherry';
    },
    truncate(string) {
      if (string && string.length < 40) return string;
      return string ? `${string.substring(0, 40)}...` : '--';
    },
    getLink(url) {
      return `https://${this.getDomain}${url}`;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.table-hover tbody tr:hover {
  cursor: pointer;
}
</style>
