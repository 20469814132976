<template>
  <div class="h-100 position-relative mt-4 pb-4">
    <div class="h-100 bg-white rounded-lg shadow-sm">
      <div class="w-100 d-flex justify-content-end position-absolute">
        <hs-button variant="link" class="p-3" id="topic-sidebar__close" @click="$emit('close')">
          <hs-icon icon="times" />
        </hs-button>
      </div>
      <div class="w-100 d-flex flex-column p-4 border-bottom">
        <div class="w-100 d-flex wrap flex-column mt-3">
          <h4 class="font-weight-bold d-flex topic-title text-center mb-3">{{ topic.title }}</h4>
          <div class="text-break topic-description">
            <p class="font-size-md mb-0 p-1 topic-title">{{ topic.description }}</p>
          </div>
        </div>
      </div>
      <div class="w-100 d-flex flex-column p-4 border-bottom" v-if="topic.answer">
        <div class="w-100 d-flex wrap flex-column mt-3">
          <label class="font-weight-bold">{{ loggedUser.first_name }}</label>
          <div class="text-break topic-description">
            <p class="font-size-md mb-0 topic-title">{{ topic.answer }}</p>
          </div>
        </div>
      </div>

      <div class="mt-4 px-3">
        <hs-group>
          <label class="font-weight-bold">{{ $t('sparkmembers.comments.sidebar.label') }}</label>
          <b-form-textarea
            class="mb-3"
            :placeholder="$t('sparkmembers.comments.sidebar.placeholder')"
            rows="5"
            spellcheck="false"
            max-rows="5"
            v-model="answer"
          />
        </hs-group>
      </div>
      <div class="mt-4 px-3">
        <hs-button
          block
          variant="outline-primary"
          :disabled="!answer"
          id="topic-sidebar__save"
          @click="$emit('send', answer)"
        >
          {{ $t('sparkmembers.comments.sidebar.button') }}
        </hs-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: {
    topic: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    answer: '',
  }),
  computed: {
    ...mapState('auth', {
      loggedUser: state => state.loggedUser,
    }),
  },
};
</script>

<style lang="scss" scoped>
.topic-title {
  word-break: break-all;
}
.topic-description {
  max-height: 200px;
  overflow: auto;
}
</style>
